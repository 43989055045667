@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  margin-bottom: 32px;
}

.videoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  width: 332px;
  margin: 0 auto;
}

iframe {
  width: 100%;
  height: 187px;
  border-radius: 12px;
}

.content {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text {
  @include body-text(md);
}

.strong {
  @include body-text(md);
  font-weight: $semiBoldFontWeight;
}
